import { CourseResponseCourses } from 'types/typescript-axios/api';
import axios, {AxiosResponse, AxiosError} from 'axios';

export function mansekiStatus(
  course: CourseResponseCourses,
): boolean {
  // return true;
  // return false;
  const applicant1: number = course.data1.applicant1;
  const applicant3: number = course.data1.applicant3;
  const capacity: any = course.data2.acf?.capacity;
  if (typeof capacity === 'undefined') {
    return false;
  }
  return Number(applicant1) + Number(applicant3) >= Number(capacity);
}

export function isWaitingList(course: CourseResponseCourses): boolean {
  // キャンセル待ち受付
  // する true
  // しない FALSE
  // return true;
  // return false;
  return Number(course.data2.acf?.waiting_cancel) === 1;
}

export function courseTimeStatus(
  course: CourseResponseCourses,
): number {
  const nowDate: Date = new Date();
  const toDate: Date = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());
  // 講座申込み期間の状態を返す
  // 申込み受付期間前：0
  // 申込み受付期間中：1
  // 申込み期間終了：2
  // 開催終了：3

  // return 0;

  const acf: any = course.data2.acf;
  if (
    typeof acf === 'undefined' ||
    typeof acf.dt_start_reception === 'undefined' ||
    typeof acf.dt_end_reception === 'undefined' ||
    typeof acf.dt_end_reception === 'undefined'
  ) {
    return 3;
  }
  // 0: 申込み受付期間前
  const matchRreservationStartDay: string[] | null = acf.dt_start_reception.match(/([0-9]{4}-[0-9]{2}-[0-9]{2})\s([0-9]{2}:[0-9]{2}:[0-9]{2})/);
  if (
    matchRreservationStartDay &&
    matchRreservationStartDay![1] &&
    matchRreservationStartDay![2]
  ) {
    const reservationStartDate: Date = new Date(`${matchRreservationStartDay![1]}T${matchRreservationStartDay![2]}`);
    if (nowDate < reservationStartDate) {
      return 0;
    }
  }
  // 3: 開催終了
  const matchCourseEndDay: string[] | null = course.data2.acf?.day?.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/) || null;
  if (
    matchCourseEndDay !== null &&
    matchCourseEndDay![1] &&
    matchCourseEndDay![2] &&
    matchCourseEndDay![3]
  ) {
    const reservationEndDate: Date = new Date(
      Number(matchCourseEndDay![1]),
      Number(matchCourseEndDay![2]) - 1,
      Number(matchCourseEndDay![3]),
    );
    if (toDate > reservationEndDate) {
      return 3;
    }
  }
  // 2: 申込み期間終了
  const matchRreservationEndDay: string[] | null = acf.dt_end_reception.match(/([0-9]{4}-[0-9]{2}-[0-9]{2})\s([0-9]{2}:[0-9]{2}:[0-9]{2})/);
  if (
    matchRreservationEndDay !== null &&
    matchRreservationEndDay![1] &&
    matchRreservationEndDay![2]
  ) {
    const reservationEndDate: Date = new Date(`${matchRreservationEndDay![1]}T${matchRreservationEndDay![2]}`);
    if (nowDate > reservationEndDate) {
      return 2;
    }
  }
  // 1: 申込み受付期間中
  return 1;
}


/**
 *
 * @param course
 * @param isLogin ログイン中か否か
 * @returns
 */
export function courseStatusText(
  course: CourseResponseCourses,
  isLogin: boolean,
): string {
  const resCourseStatus = courseTimeStatus(course);
  const status1: number = isReservedStatus(course);
  const status2: number = isReservedStatus2(course);
  const isManseki: boolean = mansekiStatus(course);
  const isCourse: number = isCourseApplicationMethod(course);
  const isWaiting: boolean = isWaitingList(course);

  if (resCourseStatus === 3) { // 講座終了
    return '';
  }

  if (
    resCourseStatus < 3
    && status1 === 0
  ) {
    return '';
  }

  if (
    resCourseStatus < 3
    && status1 === 2
    && status2 === 2
  ) {
    return '本講座の参加確認手続きを行ってください。';
  }

  if (
    resCourseStatus < 3
    && status1 === 2
    && status2 !== 2
  ) {
    return '';
  }

  if (
    resCourseStatus === 2 || // 申込み期間終了
    (
      resCourseStatus === 1 && // 受付期間中
      isManseki && // 満席
      !isWaiting && // キャンセル待ちしない
      (status1 === -1 || status1 === 1) && // 未申込み・キャンセル
      isCourse === 0 // 先着順
    )
  ) {
    return '';
  }

  if (resCourseStatus === 0) { // 受付期間前
    return '';
  }

  if (
    resCourseStatus === 1 && // 受付期間中
    isManseki && // 満席
    isWaiting && // キャンセル待ちする
    isCourse === 0 // 先着順
  ) {
    return '';
  }

  return '';
}

export async function getSuggestionList() {
  const res = await axios.get('/wp-json/moc/company');
  return res.data;
}

export function newStatusFnc(
  date: Date,
): boolean {
  let today: Date = new Date();
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 5);
  const res: boolean = (today <= date) ? true : false;
  return res;
}

export function isVisibilityOrderBtn(course: CourseResponseCourses): boolean {
  /**
   * true: 表示
   * false: 非表示
   */
  return (typeof course.data2.acf?.orderBtnHidden !== 'undefined' && course.data2.acf.orderBtnHidden === '0') ;
}

export function isReservedStatus(course: CourseResponseCourses): number {
  // -1: 未申し込み
  // 0: 申込受付
  // 1: キャンセル
  // 2: キャンセル待ち
  // return 0;
  return course.data1.status1 ? Number(course.data1.status1) : -1;
}

export function isReservedStatus2(course: CourseResponseCourses): number {
  // 講座の予約ステータス2
  // -1: 未申し込み
  // 0: -
  // 1: キャンセル待ち繰り上げ
  // 2: 繰り上げ確認中
  // 3: キャンセル料発生
  // 4: 無断キャンセル
  // 5: キャンセル待ち辞退
  // 6: 抽選結果待ち
  // 7: 先着申込み中
  // 8: オンライン申込み中
  // 9: 受講確定
  // 10: 当選（受講）辞退
  // 11: 繰り上げ確認応答なし
  // return 0;
  return course.data1.status2 ? Number(course.data1.status2) : -1;
}

export function isReservedStatus3(course: CourseResponseCourses): number {
  // 講座の支払い状況
  // -1: 未申し込み
  // 0: -
  // 1: 未入金
  // 2: 入金済
  // return 0;
  return course.data1.status3 ? Number(course.data1.status3) : -1;
}

export function isCourseApplicationMethod(course: CourseResponseCourses): number {
  // 申込方法
  // 先着順 0
  // 抽選 1
  // オンライン 2
  // return 2;
  if (typeof course.data2.acf === 'undefined' || typeof course.data2.acf?.cancel_view === 'undefined') {
    return -1;
  }
  return Number(course.data2.acf?.cancel_view);
}

export function isCancelNoteStatus(course: CourseResponseCourses): number {
  const timeStatus = courseTimeStatus(course);
  const isApplicationMethod = isCourseApplicationMethod(course);
  const isStatus1 = isReservedStatus(course);
  const isStatus2 = isReservedStatus2(course);
  const isManseki = mansekiStatus(course);
  const isWaitngListStatus = isWaitingList(course);

  if (isStatus1 === 1 || timeStatus === 3) {
    return -1;
  }

  if (
    isStatus1 === 2 // キャンセル待ち
  ) {
    if (isStatus2 === 2) {
      return 2;
    }
    /**
     * ※上記の「キャンセル」ボタンを押しますと、申込みは取り消しされます。
     */
    return 1;
  }

  if (
    isStatus1 === 0
  ) {
    /**
     * ※上記の「キャンセル」ボタンを押しますと、申込みは取り消しされます。
     */
    return 0;
  }

  return -1; // 表示なし
}

export function sortCourseList(
  courseList: CourseResponseCourses[],
): CourseResponseCourses[] {
    // 申込み受付期間前
    const noticeCourse: CourseResponseCourses[] = courseList.filter((value: CourseResponseCourses) => {
      const timeStatus = courseTimeStatus(value);
      if (timeStatus === 0) {
        return true;
      }
    })
    .sort((a, b) => {
      return new Date(b.data2.day).getTime() - new Date(a.data2.day).getTime();
    });
    // 申込み受付中
    const reserveCourse: CourseResponseCourses[] = courseList.filter((value: CourseResponseCourses) => {
      const timeStatus = courseTimeStatus(value);
      if (timeStatus === 1) {
        return true;
      }
    })
    .sort((a, b) => {
      return new Date(b.data2.day).getTime() - new Date(a.data2.day).getTime();
    });
    // 申込み受付終了
    const endCourse: CourseResponseCourses[] = courseList.filter((value: CourseResponseCourses) => {
      const timeStatus = courseTimeStatus(value);
      if (timeStatus === 2) {
        return true;
      }
    })
    .sort((a, b) => {
      return new Date(b.data2.day).getTime() - new Date(a.data2.day).getTime();
    });
    // 開催終了
    const finishCourse: CourseResponseCourses[] = courseList.filter((value: CourseResponseCourses) => {
      const timeStatus = courseTimeStatus(value);
      if (timeStatus === 3) {
        return true;
      }
    })
    .sort((a, b) => {
      return new Date(b.data2.day).getTime() - new Date(a.data2.day).getTime();
    });
    // 予告→受付中→受付終了→開催終了
    const combinedArray = noticeCourse.concat(reserveCourse, endCourse, finishCourse);
    return combinedArray;
}

export function showCancelFee(date: Date): boolean {
  const today: Date = new Date();
  const res: boolean = (today >= date) ? true : false;
  return res;
}

export function reserveStatusText(
  course: CourseResponseCourses,
): string {
  const isStatus1 = isReservedStatus(course);
  const isStatus2 = isReservedStatus2(course);
  const isStatus3 = isReservedStatus3(course);
  const timeStatus = courseTimeStatus(course);

  if (isStatus1 === 1) { // キャンセル
    if ( isStatus3 === 1 ) { // キャンセル料未払い
      return 'キャンセル<br><b class="has-text-danger">※キャンセル料をお支払いいただいておりません。</b>';
    }
    return 'キャンセル';
  }

  if (timeStatus === 3) { // 講座終了
    if (isStatus1 === 0) { // 申込受付
      return '受講済';
    }
    if (
      isStatus1 === 2 && // キャンセル待ち
      isStatus2 === 0 // -
    ) {
      return 'キャンセル待ち';
    }
    return 'キャンセル';
  }


  if (isStatus1 === 0) { // 申込受付
    if (isStatus2 === 1 || isStatus2 === 9) { // キャンセル待ち確認中
      return '受講確定';
    }
    if (isStatus2 === 6) { // 抽選結果待ち
      return '抽選結果待ち';
    }
    return '申込み中';
  }

  if (isStatus1 === 2 ) { // キャンセル待ち
    return 'キャンセル待ち申込み中';
  }

  return '未申し込み';
}
