import { ValidationProvider, extend, ValidationObserver, localize } from 'vee-validate';
import {
  required,
  // email,
  alpha,
  alpha_num,
  alpha_dash,
  alpha_spaces,
  max,
  confirmed,
  min,
  numeric,
  min_value,
  oneOf,
  regex,
} from 'vee-validate/dist/rules.js';
import ja from 'vee-validate/dist/locale/ja.json';
import axios, {AxiosResponse, AxiosError} from 'axios';
import {Scucess} from '../../types/typescript-axios/api';

let companyNames: string[] = [];

axios.get('/wp-json/moc/company')
.then((response) => {
  companyNames = response.data;
})
.catch((error) => {
  console.log(error);
});

const isUnique = async (value: string) => {
  const res = await axios.get('/wp-json/moc/account/validation/mail',
    {
      params: {
        mail: value,
      },
    },
  )
  .then((response) => {
    return {
      valid: true,
    };
  })
  .catch((error) => {
    return {
      valid: false,
      data: {
        message: 'response.data.message',
      },
    };
  });
  return res;
};

const isUniqueIgnore = async (value: string, compare: any) => {
  const res = await axios.get('/wp-json/moc/account/validation/mail',
    {
      params: {
        mail: value,
        ignore: compare.compare,
      },
    },
  )
  .then((response) => {
    return {
      valid: true,
    };
  })
  .catch((error) => {
    return {
      valid: false,
      data: {
        message: 'response.data.message',
      },
    };
  });
  return res;
};

extend('unique', {
  validate: isUnique,
  message: (data: any, data2: any) => {
    return 'このメールアドレスは既に登録されております';
  },
});

extend('uniqueIgnore', {
  params: ['compare'],
  validate: (value, compare) => {
    return isUniqueIgnore(value, compare);
  },
  message: (data: any, data2: any) => {
    return 'このメールアドレスは既に登録されております';
  },
});

extend('forbiddenString', {
  validate: (value, compare) => {
    const test = /^((?=.*\*)|(?=.*\.)|(?=.*\\)|(?=.*\=)|(?=.*\?)|(?=.*\-)|(?=.*\¥)).*$/.test(value);
    return !test;
  },
  message: (data: any, data2: any) => {
    return '「\\=?*-.」の半角文字は入力できません';
  },
});

extend('required', {
  ...required,
});
extend('email', {
  validate: (value, compare) => {
    const test = /^[a-zA-Z0-9_\.\-]+?@[A-Za-z0-9_\.\-]+$/.test(value);
    return test;
  },
  message: (data: any, data2: any) => {
    return 'メールアドレスを入力してください';
  },
});
extend('alpha', {
  ...alpha,
  validate(value: string) {
    return /^[A-Z]*$/i.test(value);
  },
});
extend('alpha_num', {
  ...alpha_num,
  validate(value: string) {
    return /^[a-zA-Z0-9\-_.,]*$/i.test(value);
  },
});
extend('alpha_dash', {
  ...alpha_dash,
  validate(value: string) {
    return /^[0-9A-Z_-]*$/i.test(value);
  },
});
extend('alpha_spaces', {
  ...alpha_spaces,
  validate(value: string) {
    return /^[A-Z\s]*$/i.test(value);
  },
});
extend('max', {
  ...max,
});
extend('confirmed', {
  ...confirmed,
});
extend('min', {
  ...min,
});
extend('numeric', {
  message: '{_field_}は半角数字のみ使用できます ',
  ...numeric,
});
extend('oneOf', {
  ...oneOf,
});
extend('regex', {
  ...regex,
});
extend('company', {
  message: '企業名はゲートシティ大崎入居企業に入居している企業に限ります',
  validate(value: string) {
    const result = companyNames.indexOf(value);
    return (result === -1) ? false : true;
  },
});
extend('password', {
  // message: 'パスワードは英数字のみ使用できます',
  message: 'ご利用いただけない文字が含まれてます',
  validate(value: string) {
    return /^[a-zA-Z0-9\-_.,]*$/i.test(value);
  },
});
// extend('campany', {
//   // エラーメッセージを設定する
//   message: '数値の先頭に0は不要です',
//   validate(value) {
//     if (value.match(/^([1-9]\d*|0)$/)) {
//       return true;
//     }
//   }
// });


localize('ja', ja);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      uniqueIgnore: {
        uniqueIgnore: true,
      },
      required: {
        required: true,
      },
      ruleAccountAgree: {
        required: {allowFalse: false},
      },
      ruleAccountNameSei: {
        required: true,
        forbiddenString: true,
        max: 25,
      },
      ruleAccountNameMei: {
        required: true,
        forbiddenString: true,
        max: 25,
      },
      ruleAccountBirthYear: {
        required: true,
      },
      ruleAccountSex: {
        required: {allowFalse: false},
      },
      ruleAccountCompanyName: {
        required: true,
        // company: true,
      },
      ruleAccountDepartmentName: {
        required: true,
        max: 40,
      },
      ruleAccountMail: {
        // unique: true,
        required: true,
        max: 50,
        email: true,
      },
      ruleAccountMailReInput: {
        required: true,
        confirmed: 'mail',
      },
      ruleForegetAccountMail: {
        required: true,
        email: true,
      },
      ruleIdentificationAccountMail: {
        required: true,
        email: true,
      },
      ruleAccountPassword: {
        required: true,
        password: true,
        min: 4,
        max: 20,
      },
      ruleOnetimePassword: {
        required: true,
      },
      ruleAccountNowPassword: {
        required: true,
      },
      ruleAccountNewPassword: {
        password: true,
        min: 4,
        max: 20,
      },
      ruleAccountPasswordReInput: {
        required: true,
        confirmed: 'password',
      },
      ruleAccountNewPasswordReInput: {
        confirmed: 'newPassword',
      },
      ruleAccountSecretQuestAans: {
        required: true,
        max: 10,
      },
      ruleAccountEditSecretQuestAans: {
        max: 10,
        required: true,
      },
      ruleAccountTel: {
        required: true,
        numeric: true,
        max: 12,
        min: 10,
      },
      ruleAccountTel1: {
        numeric: true,
      },
      ruleAccountTel2: {
        numeric: true,
      },
      ruleAccountTel3: {
        numeric: true,
      },
      ruleAccountConsent: {
        required: {allowFalse: false},
      },
      ruleLoginMail: {
        required: true,
        email: true,
      },
      ruleLoginPassword: {
        required: true,
      },
      ruleCourseAgree: {
        required: {allowFalse: false},
      },
    };
  },
};
